.cell-3 {
	width: 33.33%;
}
.cell-4 {
	width: 25%;
}
.cell-5 {
	width: 20%;
}
.cell-6 {
	width: 16.66%;
}
.cell-7 {
	width: 14.28%;
}
.cell-8 {
	width: 12.5%;
}
.cell-9 {
	width: 11.11%;
}
.cell-10 {
	width: 10%;
}
.cell-11 {
	width: 9.09%;
}
.cell-12 {
	width: 8.33%;
}
.green {
	background-color:  #5EBD3E;
}
.yellow {
	background-color: #FFB900;
}
.orange {
	background-color: #F78200;
}
.red {
	background-color: #E23838;
}
.blue {
	background-color: #009CDF;
}

