.grid {
	display: flex;
	flex-wrap: wrap;
	min-width: 20px;
	/* max-width: 60vh; */
	height: 70vw;
    max-height: 60vh;
    min-height: 20px;
	/* min-width: 360px;
	max-width: 80vh;
	height: 100vw;
    max-height: 80vh;
    min-height: 360px; */
}