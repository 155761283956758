body {
  margin: 0;
  padding: 0;
  font-family: Sans-Serif;
  background-color: #1d1e19;
}

h1 {
	margin: 0;
	color: #f6f7ee;
}

.header {
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 360px;
}

.count {
	padding: 2px;
	font-size: 10pt;
	border-width: 1px;
	border-style: solid;
	background-color: #23221c;
	color: #686964;
}

.count span {
	text-align: center;
	font-size: 16pt;
	display: block;
}

.content {
	max-width: 80vh;
}

.newgame {
	white-space: nowrap;
	margin-right: 10px;
	padding: 5px;
	background: linear-gradient(to bottom, #e0e0e0, #b0b0b0);
	cursor: pointer;
	margin: 0;
}