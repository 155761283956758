.picker {
	margin-left: 5px;
	margin-right: 5px;
	border-radius: 50px;
	min-width: 50px;
	min-height: 50px;
}

.picker:first-child {
	margin-left: 0px;
}
.picker:last-child {
	margin-right: 0px;
}