/* latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v18/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3OwRmPXws9Iq2uA.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v18/4UaZrEtFpBI4f1ZSIK9d4LjJ4o0wOwRmPXws9Iq2uA.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v18/4UaZrEtFpBI4f1ZSIK9d4LjJ4rQwOwRmPXws9Iq2uA.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff2') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.pac-container {
  background-color: #fff;
  padding: 8px;
}
.cell-3 {
	width: 33.33%;
}
.cell-4 {
	width: 25%;
}
.cell-5 {
	width: 20%;
}
.cell-6 {
	width: 16.66%;
}
.cell-7 {
	width: 14.28%;
}
.cell-8 {
	width: 12.5%;
}
.cell-9 {
	width: 11.11%;
}
.cell-10 {
	width: 10%;
}
.cell-11 {
	width: 9.09%;
}
.cell-12 {
	width: 8.33%;
}
.green {
	background-color:  #5EBD3E;
}
.yellow {
	background-color: #FFB900;
}
.orange {
	background-color: #F78200;
}
.red {
	background-color: #E23838;
}
.blue {
	background-color: #009CDF;
}


.grid {
	display: flex;
	flex-wrap: wrap;
	min-width: 20px;
	/* max-width: 60vh; */
	height: 70vw;
    max-height: 60vh;
    min-height: 20px;
	/* min-width: 360px;
	max-width: 80vh;
	height: 100vw;
    max-height: 80vh;
    min-height: 360px; */
}
.picker {
	margin-left: 5px;
	margin-right: 5px;
	border-radius: 50px;
	min-width: 50px;
	min-height: 50px;
}

.picker:first-child {
	margin-left: 0px;
}
.picker:last-child {
	margin-right: 0px;
}
.pickers {
	display: flex;
	/* width: 100%; */
	margin-bottom: 5px;
	padding: 0px;
	margin-right: 0px;
}
body {
  margin: 0;
  padding: 0;
  font-family: Sans-Serif;
  background-color: #1d1e19;
}

h1 {
	margin: 0;
	color: #f6f7ee;
}

.header {
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 360px;
}

.count {
	padding: 2px;
	font-size: 10pt;
	border-width: 1px;
	border-style: solid;
	background-color: #23221c;
	color: #686964;
}

.count span {
	text-align: center;
	font-size: 16pt;
	display: block;
}

.content {
	max-width: 80vh;
}

.newgame {
	white-space: nowrap;
	margin-right: 10px;
	padding: 5px;
	background: linear-gradient(to bottom, #e0e0e0, #b0b0b0);
	cursor: pointer;
	margin: 0;
}
/* latin */
@font-face {
  font-family: 'Permanent Marker';
  font-style: normal;
  font-weight: 400;
  src: local('Permanent Marker Regular'), local('PermanentMarker-Regular'), url(https://fonts.gstatic.com/s/permanentmarker/v8/Fh4uPib9Iyv2ucM6pGQMWimMp004La2Cf5b6jlg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
